import React from "react"
import "./blog.module.scss"
import headerStyles from "./blog.module.scss"


const Blog = (props) => {

	return (

        <div className={ `uk-section uk-section-small` }>
            <div className={ `uk-container` }>

                <h2 className={ `${headerStyles.BlogHeader}` }>Блог</h2>

                
                <div className={ `uk-grid-match uk-child-width-1-1@l uk-margin-small-top` } uk-grid="true">
     
                    <div>      
                        <div className={ `uk-flex uk-flex-row uk-flex-center uk-position-relative` } >
                            <div uk-slider="autoplay: true; autoplay-interval: 2000;">
                                <div className={ `uk-position-relative` } >

                            
                                    <ul className={ `uk-slider-items uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-child-width-1-4@xl` } >  


                                        {props.data.edges.map((edge) => {
                                            return (

                                                <li key={edge.node.slug} className={ `uk-flex uk-flex-center` }> 
                                                    <div>
                                                        <a href={`/blog/${edge.node.slug}`} className={ `uk-card uk-card-default uk-text-center uk-flex uk-flex-column uk-flex-middle  ${headerStyles.BlogCard}` } >

                                                            <img className={ `${headerStyles.BlogCardImg}` } src={edge.node.imgMini} width="255" height="215" alt={edge.node.alt}></img>
                                                            <div className={ `${headerStyles.BlogCardBackground}` }></div>

                                                            <p className={ `${headerStyles.BlogCardTitle}` }>{edge.node.title}</p>  
                                                            <p className={ `${headerStyles.BlogCardDate}` }>{edge.node.date}</p>                                             
                                                            
                                                        </a>
                                                    </div>
                                                </li>    

                                            )
                                        })}

                                                    
                                    </ul>

                                </div>

                            </div>                                         
                        </div>
                    </div>
                </div>

                <div className={ `uk-grid-match uk-child-width-1-1@l` } uk-grid="true">

                    <div>
                        <div className={ `uk-flex uk-flex-row uk-flex-center` } >
                            
                            <a href="/blog" className={ `${headerStyles.BlogButton}` } >👉 Посмотреть все статьи</a>

                        </div>
                    </div>
                
                </div>

            </div>
        </div>
        
	)
}

export default Blog


